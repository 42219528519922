import { Box } from "@material-ui/core";
import { themeV2Mui } from "@muchbetteradventures/mui-v4";
import { Person } from "@muchbetteradventures/mui-v4/dist/Icons";
import React from "react";

const primaryColor = themeV2Mui.palette.primary.main;
const defaultColor = themeV2Mui.palette.grey[500];

const BookedPersonIcon = ({
  color = "default",
}: {
  color: "primary" | "default";
}) => (
  <Person
    htmlColor={color === "primary" ? primaryColor : defaultColor}
    fontSize="large"
    style={{ marginLeft: -5 }}
  />
);
const NonBookedPersonIcon = () => (
  <Person
    fontSize="large"
    htmlColor={defaultColor}
    style={{ marginLeft: -5 }}
  />
);

const repeat = (n: number, el: any) =>
  Array(n)
    .fill(0)
    .map(() => el);

const Pax = ({
  minPax,
  maxPax,
  bookedPax,
  noWrap = false,
}: {
  minPax: number;
  maxPax: number;
  bookedPax: number;
  noWrap?: boolean;
}) => {
  const bookedPersonIconColor = bookedPax >= minPax ? "primary" : "default";
  const icons = [
    ...repeat(
      Math.max(0, bookedPax),
      <BookedPersonIcon color={bookedPersonIconColor} />
    ),
    ...repeat(Math.max(0, maxPax - bookedPax), <NonBookedPersonIcon />),
  ];
  const wrapperBoxProps = noWrap
    ? { overflow: "hidden", whiteSpace: "nowrap", position: "relative" }
    : {};
  return (
    <Box {...wrapperBoxProps}>
      {minPax > 0 && (
        <Box
          display="inline-block"
          borderRight="1px dotted grey"
          marginRight="5px"
          paddingY={1}
        >
          {icons.slice(0, minPax).map((icon) => (
            <>{icon}</>
          ))}
        </Box>
      )}
      {icons.slice(minPax).map((icon) => (
        <Box display="inline-block" paddingY={1}>
          {icon}
        </Box>
      ))}
      {noWrap && (
        <Box
          position="absolute"
          style={{
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
          }}
          width={50}
          right={0}
          top={0}
          bottom={0}
        />
      )}
    </Box>
  );
};

export default Pax;
